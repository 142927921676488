<template>
  <div>
    <a-card :bordered="true" class="dashboard-bar-chart container">
      <a-page-header>
        <a-input v-model="query.vid" placeholder="请输入编号" 
          allowClear style="width: 200px"></a-input>
        <a-select v-model="query.gender" allowClear 
          placeholder="请选择性别" style="width: 160px; margin-left:10px">
            <a-select-option value="0">母</a-select-option>
          <a-select-option value="1">公</a-select-option>
        </a-select>
        <a-select v-model="query.farm_id" allowClear 
          placeholder="请选择农场" style="width: 150px; margin-left:10px">
          <a-select-option :value="farm.value" v-for="farm, index in farms" :key="index">{{ farm.label }}</a-select-option>
        </a-select>
        <a-select v-model="query.is_dead" allowClear placeholder="请选择筛选死亡个体"
          class="margin-left-10" style="width: 170px;">
          <a-select-option :value="0">未死亡</a-select-option>
          <a-select-option :value="1">已死亡</a-select-option>
          <a-select-option :value="2">全部</a-select-option>
        </a-select>
        <a-button type="primary" class="margin-left-10" @click="refresh_list">查询</a-button>
        <a-button type="primary" class="margin-left-10" @click="move_items" :disabled="selectedRowKeys.length == 0">分配到组</a-button>
        <a-button type="primary" class="float-right" @click="openEdit('add', null)">新建个体</a-button>
      </a-page-header>

      <a-table
        :data-source="list"
        :columns="columns"
        :pagination="pagination"
        @change="handleTableChange"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      >
        <template slot="vid" slot-scope="vid, row">
          <item-button :text="vid" :id="row.id || row.rf_id || row.id" :is_dead="row.is_dead"></item-button>
        </template>
        <template slot="rf_id" slot-scope="rf_id, farm_id">
          {{ rf_id || '-' }}
        </template>
        <template slot="father" slot-scope="father, row">
          <item-button :text="row.item_father" :id="row.father_id" :is_dead="row.is_dead"></item-button>
        </template>
        <template slot="mother" slot-scope="mother, row">
          <item-button :text="row.item_mother" :id="row.mother_id" :is_dead="row.is_dead"></item-button>
        </template>
        <template slot="birth_date" slot-scope="birth_date">
          {{ birth_date || '-' }}
        </template>
        <template slot="gender" slot-scope="gender">
          {{ gender == 0 ? '母' : '公' }}
        </template>
        <template slot="operation" slot-scope="scope, row">
          <a-button type="primary" size="small" @click="openEdit('edit', row)">编辑</a-button>
        </template>
      </a-table>
    </a-card>

    <add-or-edit ref="addOrEdit"></add-or-edit>
  </div>
</template>

<script>
import ItemButton from '../components/ItemButton.vue';
import addOrEdit from '@/views/breed_items/components/addOrEdit'
export default {
  components: {
    ItemButton, addOrEdit
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      query: {
        page_no: 1,
        page_size: 20,
        vid: '',
        gender: undefined,
        farm_id: undefined,
        is_dead: undefined
      },
      pagination: {
        pageSize:20,
        current: 1,
        total: 0,
        showTotal: total => `共 ${total} 条记录`
      },
      farms: [],
      columns: [
        {
          title: "Vid",
          dataIndex: "vid",
          key: "vid",
          scopedSlots: {customRender: 'vid'}
        },
        {
          title: "RF-Id",
          dataIndex: "rf_id",
          key: "rf_id",
          scopedSlots: {customRender: 'rf_id'}
        },
        {
          title: "父亲",
          dataIndex: "father",
          key: "father",
          scopedSlots: {customRender: 'father'}
        },
        {
          title: "母亲",
          dataIndex: "mother",
          key: "mother",
          scopedSlots: {customRender: 'mother'}
        },
        {
          title: "出生日期",
          dataIndex: "birth_date",
          key: "birth_date",
          scopedSlots: {customRender: 'birth_date'}
        },
        {
          title: "性别",
          dataIndex: "gender",
          key: "gender",
          scopedSlots: {customRender: 'gender'}
        },
        {
          title: '操作',
          key: "operation",
          scopedSlots: { customRender: 'operation' },
        },
      ],
    };
  },
  created() {
    this.refresh_list();
    this.$api.get_farms().then((res) => {
      this.farms = res.data.list.map((o) => {
        return {
          label: o.name,
          value: o.id,
        };
      });
    });
  },
  methods: {
    async refresh_list() {
      const res = await this.$api.get_breed_items(this.query);
      this.list = res.data.list;
      this.pagination.total = res.data.page.total;
      this.pagination.total_pages = res.data.page.total_pages;
    },
    handleTableChange(pagination, filters, sorter) {
      this.query.page_no = pagination.current;
      this.pagination = pagination
      this.refresh_list();
    },
    openEdit(action, item) {
      if (action == 'add') {
        this.$refs.addOrEdit.open('add', item)
      } else {
        this.$refs.addOrEdit.open('edit', item)
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    move_items() {

    }
  },
};
</script>
<style lang="scss"></style>
